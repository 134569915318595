$annas-color:blue;

.annas-class {
  font-size:100px;
  color:$annas-color;
}

.welcome {
  background-color: $light;
  color: black;
  padding-top: 75px;
  padding-bottom: 25px;
  line-height: 2em;
}

.mb-3{
  width: 150px;
  height: 150px;
  margin-left: auto;
  margin-right: auto;
  display: block;

}

.card-holder{
  background-color: $light;
  padding-bottom: 25px;
}

.card-global{
  height: 450px;
  width: auto;
  font-family: lexend;
  font-size: 48px;
  color: $medium;
  font-weight: 800;
  text-shadow:2px 7px 5px rgba(0,0,0,0.3),
  0px -4px 10px rgba(255,255,255,0.3);
  padding-bottom: 25px;



  &:hover{
    transform: scale(1.05);
    box-shadow: 0 10px 20px rgba(0,0,0,.12), 0 4px 8px rgba(0,0,0,.06);
    box-shadow: inset 0px 0px 0 2000px rgba(0,0,0,0.5);
    color: $brand-primary;
  }
}

.footer-holder{
  margin-left: auto;
  margin-right: auto;
  padding-top: 50px;
  padding-bottom: 25px;
}

#site-navigation{
  background-color: $brand-primary;
  box-shadow:  0 10px 20px rgba(0,0,0,.12), 0 4px 8px rgba(0,0,0,.06) ;

    .nav-link{
      font-size: 20px;
      font-weight: 500;
    }

    #navNavLeftDropdown{
      justify-content: end;
      width: 351px;
    }

    //lol
    .justify-content-start{
      justify-content: end !important;
    }

    .justify-content-end{
      justify-content: start !important;
    }
}



.gallery-head{
  background-color: $light;
}
.gallery-holder{
  background-color: $light;
  margin-top: 2em;
}



  .main-head-text{
    font-family: lexend !important;
    font-size: 64px;
    font-weight: 800;
    color: $brand-primary;
    margin-left: 1em;
    }






.cont-head{
margin-top: 2em;
  margin-left: 3em;


  h1{
      font-family: lexend;
      font-size: 36px;
      font-weight: 700;

        }
}


.pie-holder{
  margin-top:2em;
}

.pie-head{
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  font-family: lexend;
  font-size: 24px;
  font-weight: 500;
  padding-top: 20px;
  margin-top: 1em;
}


.pie-card{
  height: 700px;
  width: auto;
  border: solid 5px $brand-primary !important;
  border-radius: 20px;


.rpb-flex-card-image {
  width: 100%;
  height: 15vw !important;
  object-fit: cover;
  border-radius: 15px 15px 0px 0px;
}


}


.order-button{
  background-color: $brand-primary;
  color: black;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 15px;
  padding-right: 15px;
  font-family: lexend;
  font-size: 16px;
  font-weight: 700;
  border-color: solid $white;
  border-radius: 25px;
  text-align: center;
  justify-content: center;
  margin-top: 1em;

  &:hover{
    transform: scale(1.05);
    color: $brand-primary;
    background-color: $accent;
    box-shadow: 0 10px 20px rgba(0,0,0,.8), 0 4px 8px rgba(0,0,0,.06);
    box-shadow: inset 0px 0px 0 2000px rgba(0,0,0,0.2);
    border: solid $brand-primary;
  }
}





.gal-img{
  border-radius: 15px
}