// Theme Colors/Branding
$brand-primary: #fdfd96;
$brand-primary-rgb: rgba($brand-primary,1);
$brand-primary-offset: darken($brand-primary,10%);
$brand-secondary: #ffc6a2;
$dark: #484738;
$light: #fefeb6;
$medium: #fdfda1;
$inactive: #8c8c8c;
$gray: #adac9a;
$white: #fff;
$accent: #f7906e;
$accent-2: #ba5c3d;
$body-font: #222;
$error: #d81e00;


.top {
  position: absolute;
  top: 0;
  left: 0;
}

.btn {
  position: fixed;
  bottom: 10px;
  right: 10px;
  z-index: 99;
  font-size: 15px;
  border: none;
  outline: none;
  background-color: blue;
  color: white;
  text-decoration: none;
  cursor: pointer;
  padding: 15px;
  border-radius: 4px;
}

.btn:hover {
  background-color: white;
  color: blue;
}

.hidden-btn {
  position: absolute;
  background-color: white;
  color: white;
  cursor: inherit;
}