/** Search form */
.search-form {
  @extend .row;
}

.search-form label {
  @extend .mb-3;

  font-weight: normal;
}

.search-form .search-field {
  @extend .form-control;
}

.search-form .search-submit {
  @extend .btn;
  @extend .btn-secondary;
}

/* Gravity Forms Styling */

.gform_legacy_markup_wrapper textarea.large {
  @include media-breakpoint-up(md) {
    height:150px!important;
  }
}

.gform_wrapper.gravity-theme .gfield input.large, .gform_wrapper.gravity-theme .gfield select.large, textarea,
.gform_legacy_markup_wrapper input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file]), .gform_legacy_markup_wrapper ul.gform_fields li.gfield select,.gform_wrapper.gravity-theme input[type=color], .gform_wrapper.gravity-theme input[type=date], .gform_wrapper.gravity-theme input[type=datetime-local], .gform_wrapper.gravity-theme input[type=datetime], .gform_wrapper.gravity-theme input[type=email], .gform_wrapper.gravity-theme input[type=month], .gform_wrapper.gravity-theme input[type=number], .gform_wrapper.gravity-theme input[type=password], .gform_wrapper.gravity-theme input[type=search], .gform_wrapper.gravity-theme input[type=tel], .gform_wrapper.gravity-theme input[type=text], .gform_wrapper.gravity-theme input[type=time], .gform_wrapper.gravity-theme input[type=url], .gform_wrapper.gravity-theme input[type=week], .gform_wrapper.gravity-theme select, .gform_wrapper.gravity-theme textarea {
  background: $white!important;
  border: none;
  outline: none !important;
  padding: 1em 1em!important;
  font-size: 1.25rem;
  border-radius: 0px!important;
  border: solid 1px $dark;
  color:$dark;
  line-height:1em;
  font-size:20px!important;
}
.gform_wrapper.gravity-theme .gfield input.medium, .gform_wrapper.gravity-theme .gfield select.medium {
  width:100%;
}
.background-trans-black .gform_wrapper.gravity-theme .gfield_label {
  color:#fff;
}
